@font-face {
  font-family: 'Proxima Nova';
  src: url('../../public/fonts/ProximaNova-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../public/fonts/ProximaNova-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../../public/fonts/EBGaramond-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
